import React from "react"
import promo from "../../src/video/rf-dance-bachata.mp4"
import Hero from "../components/hero"
import Contact from "../components/contact"
import Layout from '../components/layout-default'
import '../styles/layout.scss'
import TeamImg from "../images/team.jpeg"

export default () => (
  <Layout
    title="Bachata Classes in Orange County | RF Dance"
    description="OC's Homegrown latin dance classes with Rhythmic Fusion. $15 for a single class, or $65 for a 5 class bundle." 
    pathname="bachata-mondays"
    img={TeamImg}>
    <Hero 
      titleRed="Bachata"
      titleWhite="Mondays & Tuesdays"
      subtitle="Orange County's weeknight bachata class"
      promo={promo}
      secondaryBtnTxt="Sign up online"
      secondaryBtnLink="/classes"
      secondaryBtnType="link" />
      
    <div className="content-block">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds display--inline-block">
            <h2>Start your week right with bachata at RF.</h2>
            <p>Our Bachata classes on Mondays and Tuesdays always begin with a fun, all-levels warm-up in our main studio room. After our muscles are warm and our mindset is hot, we separate into different studio rooms for beginner, intermediate, and advanced levels.</p>
            <p>After an hour of instruction, all levels gather back into our main studio room where our instructors will review all of the evening’s material and allow participants to film the newly learned bachata movements.</p>
            <p>Our classes end with more bachata music and open floor space where all participants may practice their new moves in a friendly and welcoming social environment. <strong>You don't want to miss RF's bachata classes!</strong></p>
          </div>
          <div className="column display--inline-block has-text-centered">
            <div className="tile tile-custom is-secondary-custom">
              <p><strong>When:</strong> 8PM - 9:15PM</p>
              <p><strong>Where:</strong> RF Dance Studio<br />1517 N. Main st<br />Santa Ana, CA 92701<br/>(949) 689-1835</p>
              {/* <p><strong>$15</strong> Single Drop-in Class<br /><strong>$65</strong> 5-Class Bundle (save 13%)<br /><strong>$75</strong> Monthly Unlimited (includes Mondays Bachata, Tuesdays Bachata, Wednesdays Salsa, Thursdays Cumbia, Sunday Trainings, Monthly Workshop, and $75 Private Lessons)</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contact />
  </Layout>
)